import ImageManager from '@/components/campaign/contentBuilder/imageUpload/imageUpload';
import companyModule from '@/services/company';

export default {
  name: 'BrandInfo',
  props: {
    saveButtonText: {
      required: false,
      default: 'Save Changes'
    }
  },
  components: {
    ImageManager
  },
  data() {
    return {
      fetchingData: true,
      savingData: false,

      brandInfo: {
        brand_name: 'MyBrand',
        site_url: '',
        address: '-- Full Address Goes Here --',
        contact_email: 'contact@yourbrand.com',
        currency: '₹',
        brand_logo: '',
        font_settings: {
          url: '',
          style: ''
        }
      },

      validationRules: {
        brand_name: [
          {
            required: true,
            message: 'Brand name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 150,
            message: 'Brand name length should be 3 to 150 characters.',
            trigger: 'blur'
          }
        ],
        site_url: [
          {
            required: true,
            message: 'Site Url is required',
            trigger: 'blur'
          },
          {
            max: 1000,
            message: 'Site Url can not exceed 1000 characters.',
            trigger: 'blur'
          }
        ],
        brand_logo: [
          {
            required: true,
            message: 'Brand logo image is required.',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: 'Address is required.',
            trigger: 'blur'
          },
          {
            max: 2000,
            message: 'Address can not exceed 2000 characters.',
            trigger: 'blur'
          }
        ],
        contact_email: [
          {
            required: true,
            message: 'Contact email is required.',
            trigger: 'blur'
          }
        ],
        currency: [
          {
            required: true,
            message: 'Contact email is required.',
            trigger: 'blur'
          }
        ]
      },

      currencyList: ['₹', '$']
    };
  },

  methods: {
    onImageUpload(obj, prop, img) {
      obj[prop] = img.url;
    },

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs.brandInfoForm.validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async onSaveChanges() {
      // Validate Form
      let isValidData = await this.validateForm();
      if (!isValidData) return;

      // Make request to save data
      this.savingData = true;
      try {
        await companyModule.saveCompanyMetadata(this.brandInfo);
        this.successToast('Changes saved successfully.');
        this.$emit('onSaveBrandInfo');
        this.savingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
        this.sendingTestNotification = false;
        this.savingData = false;
      }
    },

    async fetchBrandInfo() {
      this.fetchingData = true;
      try {
        let result = await companyModule.getCompanyMetadata();
        if (result.data) {
          this.overrideJson(this.brandInfo, result.data);
        }
        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
      }
    }
  },

  mounted() {
    this.fetchBrandInfo();
  }
};
