<template>
  <div id="brandInfoPage">
    <div class="title">
      Brand Details
      <el-button class="saveButton" type="success" @click="onSaveChanges" :loading="savingData">{{ saveButtonText }}</el-button>
    </div>

    <div class="loader" v-if="fetchingData" v-loading="true"></div>

    <el-row class="mainContent" :gutter="20" v-else>
      <el-col :span="12" class="leftSideForm">
        <el-form ref="brandInfoForm" :model="brandInfo" :rules="validationRules">
          <div>
            <el-form-item label="Brand Name" prop="brand_name">
              <el-input v-model="brandInfo.brand_name" placeholder="Brand Name" maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </div>
          <el-form-item label="Brand Logo" prop="brand_logo">
            <div class="imageFormItem">
              <el-input id="imageWPushInput" v-model="brandInfo.brand_logo" placeholder=""></el-input>
              <!-- Image upload. -->
              <template>
                <el-button type="primary" plain class="imageIconBtn" @click="$refs.logoImageManager.toggleVisiblity()"> Select Image </el-button>
                <ImageManager @onSelect="onImageUpload(brandInfo, 'brand_logo', ...arguments)" ref="logoImageManager"></ImageManager>
              </template>
            </div>
            <div class="hint">Logo shown in preview can be resized later depending on your requirements.</div>
          </el-form-item>
          <el-form-item label="Brand WebSite URL" prop="site_url">
            <el-input v-model="brandInfo.site_url" placeholder="Brand Website URL" maxlength="500" show-word-limit></el-input>
            <div class="hint">Just put the domain name of your site like example.com, putting more info may break Growlytics auto link generation.</div>
          </el-form-item>
          <el-form-item label="Contact Email" prop="contact_email">
            <el-input v-model="brandInfo.contact_email" placeholder="Contact Email" maxlength="500" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="Address" prop="address">
            <el-input type="textarea" v-model="brandInfo.address" :rows="3" placeholder="Full Address" maxlength="2000" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="Currency" prop="currency">
            <el-select v-model="brandInfo.currency" style="width: 100%">
              <el-option v-for="(curr, index) in currencyList" :key="index" :label="curr" :value="curr"> </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="Email Custom Font URL" prop="emailFontUrl">
            <el-input type="textarea" :rows="5" placeholder="https://fonts.googleapis.com/css2?family=MyFonts" v-model="brandInfo.font_settings.url"></el-input>
          </el-form-item>
          <el-form-item label="Email Custom Font URL" prop="emailFontStyle">
            <el-input type="text" placeholder="font-family: 'MyFonts', sans-serif;" v-model="brandInfo.font_settings.style"></el-input>
            <div class="hint">Make sure you are also providing fall-back fonts, incase if email client does not support custom fonts, fall-back fonts will be used.</div>
          </el-form-item> -->
        </el-form>
      </el-col>

      <!-- Right side email Preview -->
      <el-col :span="12" class="rightSideOfBrandInfo">
        <!-- <div class="previewHeader">Email Preview Based On Brand Details</div> -->
        <div class="mailPreviewContainer">
          <div class="emailPreview">
            <div class="logoContainer">
              <el-image slfit="contain" :src="brandInfo.brand_logo">
                <div slot="error" class="img-error-slot">
                  <i class="el-icon-picture-outline"></i><br />
                  Logo Not Available
                </div>
              </el-image>
            </div>
            <div class="title">Thank you for shopping with {{ brandInfo.brand_name }}.</div>
            <div class="description">Whether it's an important announcement, new products or services or letting people know about a special promotion. Maybe you need to link to something. With Growlytics, you're covered. Happy emailing!</div>
            <div class="buttonContainer">
              <el-button type="info" size="small" disabled>Start Shopping</el-button>
            </div>
            <div class="footer">
              <div class="footer-header">No longer want to receive these emails? Unsubscribe.</div>
              <div class="footer-logo">{{ brandInfo.brand_name }}</div>
              <div class="footer-address">{{ brandInfo.address }}</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="./brandInfo.scss"></style>

<script>
import brandInfoComponent from './brandInfoComponent';
export default brandInfoComponent;
</script>
