<template>
  <div id="onboardingPage">
    <div class="title">Setup your account to start engaging with your customers.</div>

    <el-row :gutter="20">
      <el-col :span="6"> </el-col>
      <el-col :span="6">
        <!-- Steps -->
        <div class="steps">
          <el-steps :active="step" finish-status="success" direction="vertical">
            <el-step title="Create Account"></el-step>
            <el-step title="Integrate Your Store"></el-step>
            <el-step title="Set Brand Details"></el-step>
          </el-steps>
        </div>
      </el-col>

      <el-col :span="18">
        <!-- Branding Page -->
        <div class="stepContentContainer" v-if="step == 2">
          <BrandInfoComponent saveButtonText="Save & Continue" @onSaveBrandInfo="onBrandInfoSaved"></BrandInfoComponent>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="./onboarding.scss"></style>

<script>
import brandInfoComponent from './onboardingComponent';
export default brandInfoComponent;
</script>
