import BrandInfoComponent from '@/pages/organizationSettings/branding/brandInfo';

export default {
  name: 'BrandInfo',
  data() {
    return {
      step: 2,
      fetchingData: true
    };
  },

  components: {
    BrandInfoComponent
  },

  methods: {
    onBrandInfoSaved() {
      this.$router.push('/');
    }
  },

  mounted() {
    if (document.getElementById('sideMenu')) {
      document.getElementById('sideMenu').style.display = 'none';
    }
  },

  beforeDestroy() {
    if (document.getElementById('sideMenu')) {
      document.getElementById('sideMenu').style.display = 'block';
    }
  }
};
